
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { useAdmin } from "../../hooks/admin";
import Customer from "../../models/customer";

export default defineComponent({
  name: "CustomerOverview",
  setup() {
    const { loading, getCustomers } = useAdmin();
    const router = useRouter();

    const customers = ref<Customer[]>([]);
    const count = ref(0);

    const openCustomer = (id: string) =>
      router.push({ path: `/admin/customers/${id}` });

    onMounted(async () => {
      const response = await getCustomers();

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        customers.value = response.data.customers;
        count.value = response.data.count;
      }
    });

    return { customers, count, loading, openCustomer };
  },
});
