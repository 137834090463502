
import { useAuthStore } from "@/store";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TheHeader",
  setup() {
    const store = useAuthStore();
    const router = useRouter();

    const { isLoggedIn, isAdmin } = storeToRefs(store);

    const logout = () => {
      store.logout();
      router.push({ name: "login" });
    };

    return { isLoggedIn, isAdmin, logout };
  },
});
