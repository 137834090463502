
import { defineComponent, onMounted, ref } from "vue";
import { LocationQueryValue, useRoute, useRouter } from "vue-router";

import { useAuthStore } from "@/store";
import { useHttp } from "../hooks/http";

export default defineComponent({
  name: "LoginView",
  setup() {
    const store = useAuthStore();
    const router = useRouter();
    const route = useRoute();

    const { loading, httpPost } = useHttp();

    const loginForm = ref<HTMLFormElement | null>(null);
    const emailInput = ref<HTMLInputElement | null>(null);
    const passwordInput = ref<HTMLInputElement | null>(null);

    const showConfirmation = ref(false);
    const showInvitation = ref(false);
    const showUnauthorized = ref(false);
    const showError = ref(false);

    onMounted(() => {
      const invitation = (
        route.query.invitation as LocationQueryValue
      )?.toString();

      if (invitation) {
        showInvitation.value = true;
      }

      const confirmation = (
        route.query.confirmation as LocationQueryValue
      )?.toString();

      if (confirmation) {
        showConfirmation.value = true;
      }
    });

    const login = async () => {
      loading.value = true;

      const email = emailInput.value?.value;
      const password = passwordInput.value?.value;
      loginForm.value?.reset();

      const confirmation = (
        route.query.confirmation as LocationQueryValue
      )?.toString();

      const response = await httpPost<{ idToken: string; accessToken: string }>(
        "/api/auth/login",
        {
          email,
          password,
          confirmation,
        }
      );

      if (response.error || !response.data) {
        emailInput.value?.focus();

        if (response.status === 403) {
          showUnauthorized.value = true;
        } else {
          showError.value = true;
        }
      } else {
        store.login(response.data);
        const query = confirmation ? { confirmation: "success" } : undefined;
        router.replace({ name: store.isAdmin ? "customers" : "home", query });
      }
    };

    return {
      loginForm,
      emailInput,
      passwordInput,
      loading,
      login,
      showConfirmation,
      showInvitation,
      showUnauthorized,
      showError,
    };
  },
});
