
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useAdmin } from "@/hooks/admin";
import User from "@/models/user";

export default defineComponent({
  name: "UserDetails",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id as string;

    const user = ref<User>();

    const { loading, getUser, deleteUser } = useAdmin();

    onMounted(async () => {
      const response = await getUser(id);

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        user.value = response.data.user;
      }
    });

    const deleteUserHandler = async () => {
      if (
        confirm("Sind Sie sicher, dass Sie den Nutzer löschen möchten?") ===
        true
      ) {
        const response = await deleteUser(id);

        if (response.error) {
          console.error(response.error);
        } else {
          router.push({ name: "users" });
        }
      }
    };

    return {
      loading,
      user,
      deleteUserHandler,
    };
  },
});
