
import { useAuthStore } from "@/store";
import { defineComponent, onMounted, ref } from "vue";
import { LocationQueryValue, useRoute, useRouter } from "vue-router";
import { useInvoice } from "../hooks/invoice";
import InvoiceCard from "../components/invoice/InvoiceCard.vue";
import Invoice from "@/models/invoice";

export default defineComponent({
  name: "HomeView",
  components: { InvoiceCard },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { firstName, lastName } = useAuthStore();
    const { loading, getInvoices } = useInvoice();
    const showConfirmation = ref(false);

    const currentInvoice = ref<Invoice>();
    const openInvoices = ref<Invoice[]>();
    const payedInvoices = ref<Invoice[]>();

    const yearMax = new Date().getFullYear();
    const yearMin = yearMax - 10;

    const year = ref(yearMax);

    const loadMoreInvoices = async () => {
      while (year.value > yearMin) {
        year.value--;

        const response = await getInvoices(year.value);

        if (response.error || !response.data) {
          console.error(response.error);
          break;
        } else {
          if (
            response.data.current &&
            response.data.current.id !== currentInvoice.value?.id
          ) {
            if (currentInvoice.value) {
              payedInvoices.value?.push(response.data.current);
            } else {
              currentInvoice.value = response.data.current;
            }

            payedInvoices.value?.push(...response.data.payed);

            break;
          }
        }
      }
    };

    onMounted(async () => {
      const confirmation = (
        route.query.confirmation as LocationQueryValue
      )?.toString();

      if (confirmation) {
        showConfirmation.value = true;
      }

      router.push({ query: undefined });

      const response = await getInvoices(year.value);

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        currentInvoice.value = response.data.current;
        openInvoices.value = response.data.open;
        payedInvoices.value = response.data.payed;
      }
    });

    return {
      showConfirmation,
      firstName,
      lastName,
      currentInvoice,
      openInvoices,
      payedInvoices,
      yearMin,
      year,
      loadMoreInvoices,
    };
  },
});
