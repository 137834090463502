
import { defineComponent, onMounted, ref } from "vue";
import { Toast } from "bootstrap";

import { useAdmin } from "../hooks/admin";

export default defineComponent({
  name: "AdminView",
  setup() {
    const { loading, triggerSync } = useAdmin();

    const syncToastRef = ref<HTMLElement | null>(null);
    let syncToast: Toast;
    const syncErrorToastRef = ref<HTMLElement | null>(null);
    let syncErrorToast: Toast;

    onMounted(() => {
      if (syncToastRef.value) {
        syncToast = new Toast(syncToastRef.value);
      }

      if (syncErrorToastRef.value) {
        syncErrorToast = new Toast(syncErrorToastRef.value);
      }
    });

    const sync = async () => {
      const response = await triggerSync();

      if (response.error) {
        syncErrorToast.show();
      } else {
        syncToast.show();
      }
    };

    return { loading, sync, syncToastRef, syncErrorToastRef };
  },
});
