import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";

enum Stores {
  Auth = "auth",
}

const useSessionStorage = <S>(storeId: Stores, initialState: S) => {
  const item = sessionStorage.getItem(storeId);

  if (item) {
    const state = JSON.parse(item);
    initialState = { ...initialState, ...state };
  }

  return initialState;
};

interface AuthState {
  isLoggedIn: boolean;
  isAdmin: boolean;
  idToken: string | null;
  accessToken: string | null;
  firstName?: string;
  lastName?: string;
}

export const useAuthStore = defineStore(Stores.Auth, {
  state: (): AuthState =>
    useSessionStorage<AuthState>(Stores.Auth, {
      isLoggedIn: false,
      isAdmin: false,
      idToken: null,
      accessToken: null,
      firstName: undefined,
      lastName: undefined,
    }),
  actions: {
    login({ idToken, accessToken }: { idToken: string; accessToken: string }) {
      this.idToken = idToken;
      this.accessToken = accessToken;
      const decoded = jwt_decode<{ admin?: boolean }>(accessToken);
      const decodedId = jwt_decode<{
        email?: string;
        given_name?: string;
        family_name?: string;
      }>(idToken);
      if (decodedId.given_name) {
        this.firstName = decodedId.given_name;
      }
      if (decodedId.family_name) {
        this.lastName = decodedId.family_name;
      }
      this.isAdmin = !!decoded?.admin;
      this.isLoggedIn = true;
    },
    logout() {
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.idToken = null;
      this.accessToken = null;
      this.firstName = undefined;
      this.lastName = undefined;
    },
  },
});
