import {
  createRouter,
  createWebHistory,
  NavigationGuardWithThis,
  RouteRecordRaw,
} from "vue-router";

import { useAuthStore } from "@/store";
import HomeView from "../views/HomeView.vue";
import AdminView from "../views/AdminView.vue";
import LoginView from "../views/LoginView.vue";
import SignupView from "../views/SignupView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import CustomerOverview from "../components/admin/CustomerOverview.vue";
import CustomerDetails from "../components/admin/CustomerDetails.vue";
import UserOverview from "../components/admin/UserOverview.vue";
import UserDetails from "../components/admin/UserDetails.vue";

declare module "vue-router" {
  interface RouteMeta {
    isAdmin?: boolean;
    requiresAuth: boolean;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    alias: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: { requiresAuth: false },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    redirect: {
      name: "customers",
    },
    children: [
      {
        path: "customers",
        name: "customers",
        component: CustomerOverview,
      },
      {
        path: "customers/:id",
        component: CustomerDetails,
      },
      {
        path: "users",
        name: "users",
        component: UserOverview,
      },
      {
        path: "users/:id",
        component: UserDetails,
      },
    ],
    meta: { requiresAuth: true, isAdmin: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const authGuard: NavigationGuardWithThis<undefined> = (to, from) => {
  const store = useAuthStore();
  if (store.isLoggedIn) {
    if (to.name === "login" || (to.name === "admin" && !store.isAdmin)) {
      return from;
    }
  } else {
    if (to.meta.requiresAuth !== false) {
      return {
        name: "login",
      };
    }
  }
};

router.beforeEach(authGuard);

export default router;
