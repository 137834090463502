
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { useAdmin } from "../../hooks/admin";
import User from "../../models/user";

export default defineComponent({
  name: "UserOverview",
  setup() {
    const { loading, getUsers } = useAdmin();
    const router = useRouter();

    const users = ref<User[]>([]);
    const count = ref(0);

    const openUser = (id: string) =>
      router.push({ path: `/admin/users/${id}` });

    onMounted(async () => {
      const response = await getUsers();

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        users.value = response.data.users;
        count.value = response.data.count;
      }
    });

    return { users, count, loading, openUser };
  },
});
