import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import { useAuthStore } from "./store";

import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.scss";
import "@/assets/sass/main.scss";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

useAuthStore().$subscribe((mutation, state) => {
  sessionStorage.setItem(mutation.storeId, JSON.stringify(state));
});

app.mount("#app");
