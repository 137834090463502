import Customer from "../models/customer";
import User from "../models/user";
import { useHttp } from "./http";

export const useAdmin = () => {
  const { loading, httpGet, httpPost, httpDelete } = useHttp();

  const triggerSync = async () => await httpPost("/api/admin/sync");

  const getCustomers = async () =>
    await httpGet<{ count: number; customers: Customer[] }>(
      "/api/admin/customers"
    );
  const getCustomer = async (id: string) =>
    await httpGet<{ customer: Customer }>(`/api/admin/customers/${id}`);

  const getInvitationLink = async (id: string, email: string) =>
    await httpGet<{ link: string }>(
      `/api/admin/invitation/${id}?email=${encodeURIComponent(email)}`
    );

  const getUsers = async () =>
    await httpGet<{ count: number; users: User[] }>("/api/admin/users");
  const getUser = async (id: string) =>
    await httpGet<{ user: User }>(`/api/admin/users/${id}`);
  const deleteUser = async (id: string) =>
    await httpDelete(`/api/admin/users/${id}`);

  return {
    loading,
    triggerSync,
    getCustomers,
    getCustomer,
    getInvitationLink,
    getUsers,
    getUser,
    deleteUser,
  };
};
