
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HomeView",
  setup() {
    const router = useRouter();

    const goToHome = () => router.replace({ name: "home" });

    return { goToHome };
  },
});
