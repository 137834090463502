import { useHttp } from "./http";
import type Invoice from "../models/invoice";

export const useInvoice = () => {
  const { loading, httpGet, httpDownload } = useHttp();

  const getInvoices = async (year?: number) =>
    await httpGet<{ current: Invoice; open: Invoice[]; payed: Invoice[] }>(
      `/api/invoices${year ? "?year=" + year : ""}`
    );

  const downloadInvoice = async (invoiceId: string, hash: string) =>
    await httpDownload(`/api/invoices/${invoiceId}/${hash}`);

  return {
    loading,
    getInvoices,
    downloadInvoice,
  };
};
