
import { defineComponent, toRefs, PropType, computed } from "vue";
import { useInvoice } from "../../hooks/invoice";
import Invoice from "../../models/invoice";

export default defineComponent({
  name: "InvoiceCard",
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },
  setup(props) {
    const { downloadInvoice } = useInvoice();

    const { invoice } = toRefs(props);

    const date = computed(() =>
      new Date(invoice.value.invoiceDate).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );

    const sendDate = computed(() =>
      invoice.value.sendDate
        ? new Date(invoice.value.sendDate).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : undefined
    );

    const sum = computed(() =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(invoice.value.sumGross))
    );

    const downloadInvoiceHandler = async () => {
      const response = await downloadInvoice(
        invoice.value.id,
        invoice.value.hash
      );

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response.data as Blob);
        link.download = "Rechnung.pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      }
    };

    return { date, sum, sendDate, downloadInvoiceHandler };
  },
});
