<template>
  <footer class="footer">
    <div class="container">made with ♥ by zunicode</div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  background-color: var(--bs-dark);
  color: white;
  padding: 1.875rem 0;

  .logo {
    height: 3.375rem;
  }
}
</style>
