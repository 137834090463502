
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import jwt_decode, { JwtPayload } from "jwt-decode";

import { useAdmin } from "@/hooks/admin";
import Customer from "@/models/customer";

export default defineComponent({
  name: "CustomerDetails",
  setup() {
    const route = useRoute();
    const id = route.params.id as string;

    const customer = ref<Customer>();
    const parent = ref<Customer>();
    const invitationForm = ref<HTMLFormElement | null>(null);

    const invitationEmail = ref<string>();
    const invitationLink = ref<string>();
    const decodedToken = ref<JwtPayload>();

    const { loading, getCustomer, getInvitationLink } = useAdmin();

    onMounted(async () => {
      invitationForm.value?.reset();
      invitationEmail.value = undefined;
      invitationLink.value = undefined;

      const response = await getCustomer(id);

      if (response.error || !response.data) {
        console.error(response.error);
      } else {
        customer.value = response.data.customer;

        if (response.data.customer.parent) {
          const response2 = await getCustomer(response.data.customer.parent);

          if (response2.error || !response2.data) {
            console.error(response2.error);
          } else {
            parent.value = response2.data.customer;
          }
        }
      }
    });

    const createInvitationLink = async () => {
      const email = invitationEmail.value;
      if (email && email.length > 0 && email.includes("@")) {
        const response = await getInvitationLink(id, email);

        if (response.error || !response.data) {
          console.error(response.error);
        } else {
          invitationLink.value = response.data.link;
          decodedToken.value = jwt_decode<JwtPayload>(
            response.data.link.split("?")[1]
          );
        }
      }
    };

    const copyInvitationLink = async () => {
      const link = invitationLink.value;

      if (link) {
        await navigator.clipboard.writeText(link);
      }
    };

    return {
      loading,
      customer,
      parent,
      invitationForm,
      createInvitationLink,
      invitationEmail,
      invitationLink,
      decodedToken,
      copyInvitationLink,
    };
  },
});
