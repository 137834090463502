import { Ref, ref } from "vue";
import { useAuthStore } from "../store/index";

type HttpMethod =
  | "GET"
  | "HEAD"
  | "POST"
  | "PUT"
  | "DELETE"
  | "CONNECT"
  | "OPTIONS"
  | "TRACE"
  | "PATCH";

interface HttpResponse<T> {
  readonly headers?: Headers;
  readonly status?: number;
  readonly statusText?: string;
  readonly url?: string;
  readonly data?: T;
  readonly error?: unknown;
}

const fetchWithAuthorization = async <T>(
  method: HttpMethod,
  url: string,
  token?: string,
  body?: T
) => {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });
};

const tryFetchWithLoading =
  (method: HttpMethod, loading: Ref<boolean>, token?: string) =>
  async <T, S = unknown>(url: string, body?: S): Promise<HttpResponse<T>> => {
    loading.value = true;

    try {
      const response = await fetchWithAuthorization(method, url, token, body);

      const _to = (res: Response): HttpResponse<T> => ({
        headers: res.headers,
        status: res.status,
        statusText: res.statusText,
        url: res.url,
      });

      if (response.ok) {
        try {
          const data = await response.json();
          return { ..._to(response), data };
        } catch (_) {
          return { ..._to(response) };
        }
      } else {
        const result = { ..._to(response), error: response.statusText };
        return result;
      }
    } catch (error) {
      return { error };
    } finally {
      loading.value = false;
    }
  };

const tryDownloadWithLoading =
  (loading: Ref<boolean>, token?: string) =>
  async <S = unknown>(
    url: string,
    body?: S
  ): Promise<HttpResponse<unknown>> => {
    loading.value = true;

    try {
      const response = await fetchWithAuthorization("GET", url, token, body);

      const _to = (res: Response): HttpResponse<unknown> => ({
        headers: res.headers,
        status: res.status,
        statusText: res.statusText,
        url: res.url,
      });

      if (response.ok) {
        try {
          const data = await response.blob();
          return { ..._to(response), data };
        } catch (_) {
          return { ..._to(response) };
        }
      } else {
        const result = { ..._to(response), error: response.statusText };
        return result;
      }
    } catch (error) {
      return { error };
    } finally {
      loading.value = false;
    }
  };

export const useHttp = () => {
  const { accessToken } = useAuthStore();
  const loading = ref(false);

  const _fetch = (method: HttpMethod) =>
    tryFetchWithLoading(method, loading, accessToken ?? undefined);

  const _download = () =>
    tryDownloadWithLoading(loading, accessToken ?? undefined);

  const httpGet = _fetch("GET");
  const httpPost = _fetch("POST");
  const httpPut = _fetch("PUT");
  const httpDelete = _fetch("DELETE");
  const httpDownload = _download();

  return { loading, httpGet, httpPost, httpPut, httpDelete, httpDownload };
};
