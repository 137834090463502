
import { defineComponent, onMounted, ref } from "vue";
import { LocationQueryValue, useRoute, useRouter } from "vue-router";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useHttp } from "../hooks/http";

export default defineComponent({
  name: "SignupView",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { loading, httpPost } = useHttp();

    const signupForm = ref<HTMLFormElement | null>(null);
    const firstNameInput = ref<HTMLInputElement | null>(null);
    const lastNameInput = ref<HTMLInputElement | null>(null);
    const passwordInput = ref<HTMLInputElement | null>(null);
    const passwordInput2 = ref<HTMLInputElement | null>(null);

    const showError = ref("");

    const invitationToken = ref();
    const email = ref();

    onMounted(() => {
      loading.value = true;

      try {
        const token = (
          route.query.invitation as LocationQueryValue
        )?.toString();

        if (token) {
          const decoded = jwt_decode<JwtPayload & { scope: string }>(
            token?.toString()
          );

          if (decoded && decoded.scope === "invitation") {
            email.value = decoded.sub;
            invitationToken.value = token;
          }
        } else {
          showError.value =
            "Der angegebene Einladungs-Link ist nicht (mehr) gültig. Bitte kontaktieren Sie Ihren Ansprechpartner.";
        }
      } catch (error) {
        console.error(error);
        showError.value =
          "Der angegebene Einladungs-Link ist ungültig. Bitte kontaktieren Sie Ihren Ansprechpartner.";
      }

      loading.value = false;
    });

    const signup = async () => {
      loading.value = true;

      const token = invitationToken.value;
      const firstName = firstNameInput.value?.value;
      const lastName = lastNameInput.value?.value;
      const password = passwordInput.value?.value;
      const password2 = passwordInput2.value?.value;

      if (passwordInput.value) {
        passwordInput.value.value = "";
      }

      if (passwordInput2.value) {
        passwordInput2.value.value = "";
      }

      if (
        token &&
        firstName &&
        lastName &&
        password &&
        password2 &&
        password === password2
      ) {
        const response = await httpPost("/api/auth/signup", {
          token,
          firstName,
          lastName,
          password,
        });

        if (response.error) {
          passwordInput.value?.focus();
          if (response.status === 400) {
            showError.value =
              "Der angegebene Einladungs-Link ist nicht (mehr) gültig. Bitte kontaktieren Sie Ihren Ansprechpartner.";
          } else {
            showError.value =
              "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unseren Support.";
          }
        } else {
          router.replace({ name: "login", query: { invitation: "success" } });
        }
      } else if (!token) {
        showError.value =
          "Der angegebene Einladungs-Link ist ungültig. Bitte kontaktieren Sie Ihren Ansprechpartner.";
        loading.value = false;
      } else if (password !== password2) {
        passwordInput.value?.focus();
        showError.value =
          "Die angegebenen Passwörter stimmen nicht überein. Bitte versuchen Sie es noch einmal.";
        loading.value = false;
      } else {
        showError.value =
          "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unseren Support.";
        loading.value = false;
      }
    };

    return {
      signupForm,
      email,
      firstNameInput,
      lastNameInput,
      passwordInput,
      passwordInput2,
      loading,
      signup,
      showError,
    };
  },
});
